import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ClientForm from './ClientForm';

class ClientTextField extends ClientForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isValid: true,
      stateHelperText: '',
    };
    this.checkValidity = this.checkValidity.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.data !== this.props.data) {
      if (this.props.data !== undefined) {
        if (this.props.attribute === 'p2p_source') {
          this.checkValidity(nextProps);
        }
        return true;
      }
    }
    return false;
  }

  checkValidity(entry) {
    /**
     * This function checks the validity of the input based on its type and changes the helper text
     * and validity state accordingly
     * @param {Object} entry - The event object
     * @return {void}
     */
    // check if the entry type is number
    if (this.state.inputType === 'number') {
      const { value } = entry.target;
      const isANumber = !Number.isNaN(Number(value));
      // check if the entry is a percentage
      if (this.state.adornment === '%' || this.state.attribute === 'donor_prop') {
        // if it's a percentage, check if it's an integer between 0 and 100
        // if it's the donor_prop attribute, check that is is an int or a decimal
        if ((isANumber && value >= 0 && value <= 100) || value === '') {
          this.setState({ ...this.state, isValid: true, stateHelperText: '' });
          this.updateClientState(entry);
          this.updateFormValidity(true);
        } else {
          this.setState({ ...this.state, isValid: false, stateHelperText: this.state.attribute === 'donor_prop' ? 'Must be an integer between 0 and 100 or decimal form of a percent' : 'Must be an integer between 0 and 100.' });
          this.updateFormValidity(false);
        }
        // check if the entry is an integer
      } else if (isANumber || value === '') {
        this.setState({ ...this.state, isValid: true, stateHelperText: '' });
        this.updateClientState(entry);
        this.updateFormValidity(true);
        // if neither, set the state to invalid
      } else {
        this.setState({ ...this.state, isValid: false, stateHelperText: 'Must be an integer.' });
        this.updateFormValidity(false);
      }
    }
    // if the entry type is text, set the state to valid for now
    this.updateClientState(entry);
  }

  render() {
    return (
      <div key={this.state.attribute}>
        <InputLabel
          disabled={this.state.isDisabled}
          variant="standard"
          htmlFor={this.state.attribute}
          required={this.state.required}
        >
          {this.state.label}
        </InputLabel>
        <TextField
          error={!this.state.isValid}
          onChange={this.checkValidity}
          fullWidth
          // added prop to override the current default (false)
          disabled={this.props.isDisabled || this.state.isDisabled}
          id={this.state.attribute}
          value={this.props.data ? this.props.data : ''}
          name={this.state.attribute}
          style={{ minWidth: 200, maxWidth: 450 }}
          size="small"
          inputProps={{
            'data-testid': this.state.testingId,
            endAdornment: <InputAdornment position="end">{this.state.adornment}</InputAdornment>,
          }}
          type={this.state.type}
          helperText={this.props.helperText ? this.props.helperText : this.state.stateHelperText}
          required={this.state.required}
        />
      </div>
    );
  }
}

export default ClientTextField;
